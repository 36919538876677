import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

import Context from "provider/context";

import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function App(params) {
  const [refEmail, setrefEmail] = useState();
  return (
    <>
      {!refEmail && <ForgetForm hasSubmit={(e) => setrefEmail(e)} />}
      {refEmail && <ForgetSubmited email={refEmail} />}
    </>
  );
}

function ForgetSubmited({ email }) {
  return (
    <Stack spacing={2}>
      <Typography textAlign={"center"}>
        We have sent an email to <br />
        {email}
      </Typography>
      <Typography textAlign={"center"}>
        Please check your email in order to continue the process
      </Typography>
      <Button href="/">Back to Homepage</Button>
    </Stack>
  );
}

function ForgetForm({ hasSubmit }) {
  const { setisLoading } = React.useContext(Context);
  const [error, seterror] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (payload) => {
      setisLoading(true);
      seterror("");

      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "auth/forgetpassword",
          payload
        )
        .then((res) => {
          setisLoading(false);
          if (res.status === 200) {
            //logic
            hasSubmit(payload.email);
          }
        })
        .catch((error) => {
          setisLoading(false);
          console.log(error);
          seterror("incorrect email or password");
        });
    },
  });

  return (
    <Stack alignItems={"center"} spacing={2}>
      <Typography component="h1" variant="h5">
        Forget Password
      </Typography>
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Email"
        autoComplete="new-password"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        onClick={formik.handleSubmit}
      >
        Password recovery
      </Button>
      <Typography
        variant="caption"
        display="block"
        color="red"
        align="center"
        minHeight={"20px"}
        mt={1}
      >
        {error}
      </Typography>
    </Stack>
  );
}
