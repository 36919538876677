import { Box, Stack, Grid } from "@mui/material";
// import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";

// import logoImg from "assets/img/logo.png";
import bpotImg from "assets/img/bpot.svg";

import ojklogoImg from "assets/img/ojklogo.png";
import idxlogoImg from "assets/img/idxlogo.png";
import idxtextImg from "assets/img/idxtext.png";
import kseiImg from "assets/img/ksei.png";
import idclearImg from "assets/img/idclear.png";

import playstoreImg from "assets/img/landing/playstore.png";
import appstoreImg from "assets/img/landing/appstore.png";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

// import { sitesFeatures, sitesEducation } from "view/layout/nav";

export default function App(props) {
  return (
    <Grid
      container
      bgcolor="#190939"
      width={"100vw"}
      minHeight={"280px"}
      justifyContent={"center"}
      alignItems={"flex-start"}
      py="50px"
      m={0}
      px={3}
      rowSpacing={2}
      overflow={"hidden"}
    >
      <Grid item xs={12} md={3} justifyContent={"center"} mt={3}>
        <Stack direction={"column"} width={"100%"} spacing={3}>
          <Box width={174}>
            <img src={bpotImg} alt="" className="img-contain" />
          </Box>
          <Stack direction={"column"} spacing={2}>
            <Typography className="f-white" variant="body2">
              <h3>Customer Service:</h3>
              cs.bpot@bumiputerasekuritas.co.id
                <br/>
                +62 811 149 8118 (Whatsapp)
            </Typography>
            {/* WILL REENABLE THIS ONCE CONFIRMED FROM CLIENT
            Customer Service :
            +62 811 149 8118
            cs.bpot@bumiputerasekuritas.co.id
            <Typography className="f-white" variant="body2">
              +6221 522 4560 (Hotline)
            </Typography> */}
            <Typography className="f-white" variant="body2" style={{fontSize:'10px'}}>
              <i>
                PT Bumiputera Sekuritas Estabilished in Desember 2003 as subsidiary of Asuransi Jiwa Bersama or known as AJB Bumiputera. The company enganged in financial services with major business stock brokerage service for investor and traders. PT Bumiputera Sekuritas registered and supervised by OJK KEP 157/PM/1991 and KEP 158/PM/1991.
              </i>
            </Typography>
              <a
                  href="/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <Typography className="f-white" variant="body2" style={{fontSize:'12px'}}>
                      Privacy & Policy
                  </Typography>
              </a>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} md={2} mt={3}></Grid>

      <Grid item xs={12} md={2} mt={3}></Grid>

      <Grid item xs={12} md={4} mt={3}>
        <Stack direction={"column"}>
          <Typography className="f-white">
            Registered and Authorized by :
          </Typography>
          <Stack
            spacing={2}
            direction={"row"}
            mt={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}
          >
            <a
              href="https://www.ojk.go.id/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box width={100}>
                <img src={ojklogoImg} className="img-contain" alt="" />
              </Box>
            </a>
            <a
              href="https://www.idx.co.id/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Stack alignItems={"center"}>
                <Box width={"45px"}>
                  <img src={idxlogoImg} alt="" />
                </Box>
                <img src={idxtextImg} alt="" />
              </Stack>
            </a>
            <a
              href="https://www.ksei.co.id/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box width={100}>
                <img src={kseiImg} className="img-contain" alt="" />
              </Box>
            </a>
            <a
              href="https://www.idclear.co.id/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box width={100}>
                <img src={idclearImg} className="img-contain" alt="" />
              </Box>
            </a>
          </Stack>
        </Stack>
        <Stack direction={"column"} mt={2}>
          <Typography className="f-white">Media sosial </Typography>
          <Stack direction={"row"} alignItems={"center"} mt={2} spacing={2}>
            <a
              href="https://www.facebook.com/bumiputera.sekuritas.7/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon fontSize="medium" className="f-white" />
            </a>
            <a
              href="https://www.instagram.com/bumiputerasekuritas/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon fontSize="medium" className="f-white" />
            </a>
            {/* <a href="#">
              <YouTubeIcon fontSize="medium" className="f-white" />
            </a> */}
            <a 
              href="https://twitter.com/bpsekuritas"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon fontSize="medium" className="f-white" />
            </a>
          </Stack>

          <Stack direction={"row"} alignItems={"flex-end"} mt={2} spacing={2}>
            <a
              href="https://play.google.com/store/apps/details?id=com.fgsinfotama.skytrade.bumiputera"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box height={"36px"}>
                <img src={playstoreImg} className="img-contain" alt="" />
              </Box>
            </a>
            <a
              href="https://apps.apple.com/id/app/bpot/id1495503581"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Stack height={"36px"}>
                <img src={appstoreImg} className="img-contain" alt="" />
              </Stack>
            </a>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Typography textAlign={"center"} my={2} variant="caption">
          © 2021 PT. Bumiputera Sekuritas all right reserved
        </Typography>
      </Grid>
    </Grid>
  );
}
