import React from "react";
import { Box, Stack } from "@mui/material";
import { Ticker, SingleTicker } from "react-ts-tradingview-widgets";
import { Helmet } from "react-helmet";
import { useRef } from "react";
import Parser from "html-react-parser";

export default function App(props) {
  return (
    <Stack width={"100vw"} maxHeight="72px" overflow="hidden">
      <iframe
        src="https://localhost/bumiputra/be/widget"
        title="description"
        frameBorder="0"
        width={"100%"}
        bgcolor="blue"
      ></iframe>
    </Stack>
  );
}
