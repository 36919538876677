import React from "react";
import { Box, Stack } from "@mui/material";
import { Ticker, SingleTicker } from "react-ts-tradingview-widgets";
import { Helmet } from "react-helmet";
import { useRef } from "react";
import Parser from "html-react-parser";

export default function App(props) {
  return (
    <Stack
      width={"100vw"}
      maxHeight="72px"
      overflow="hidden"
      sx={{
        background: "rgba(0, 0, 0, 0.564866)",
        backdropFilter: "blur(38.0559px)",
      }}
    >
      <iframe
        src={process.env.REACT_APP_BE_URL + "widget/hometicker"}
        title="description"
        frameBorder="0"
        width={"100%"}
        bgcolor="blue"
      ></iframe>
    </Stack>
  );
}
