import React, { useState } from "react";

import Context from "provider/context";
import useAxios from "axios-hooks";
import {
  Stack,
  Grid,
  Typography,
  Divider,
  Button,
  Pagination,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fdate } from "component/utils/formating";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";

export default function App(props) {
  const { setisLoading } = React.useContext(Context);
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [{ data, loading }] = useAxios(
    {
      url: process.env.REACT_APP_API_BASE_URL + "news",
      method: "get",
    },
    { useCache: false }
  );

  React.useEffect(() => {
    loading && setisLoading(true);
    !loading && setisLoading(false);
  });

  if (!data) return "";
  return (
    <Stack width={"100%"} my={3}>
      <Stack direction={"row"} spacing={2} alignItems={"center"} mb={3}>
        <SearchIcon />
        <Typography variant="h4">Latest News</Typography>
      </Stack>
      {isLarge && <DesktopLayout data={data} />}
      {!isLarge && <DesktopLayout data={data} />}
    </Stack>
  );
}

export function DesktopLayout({ data }) {
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(2);
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up("md"));

  function handlePaginate(e, value) {
    console.log(value);
    setpage((p) => value);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          {data
            .filter((d) => d.newsgroup.title === "News")
            .splice((page - 1) * 5, 5)
            .map((d, ix) => (
              <Stack component={Link} to={`/news/view/${d.id}`} key={d.id}>
                {ix === 0 && <DesktopCardFull d={d} />}
                {ix > 0 && <DesktopCardSmall d={d} />}
              </Stack>
            ))}
        </Stack>
        <Stack alignItems={"center"} my={2}>
          <Pagination
            count={Math.floor(
              data.filter((d) => d.newsgroup.title === "News").length / 5
            )}
            onChange={handlePaginate}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack
          width={"100%"}
          spacing={2}
          sx={{ background: "rgba(255, 255, 255, 0.08)" }}
          borderRadius={"12px"}
          p={2}
        >
          <Typography variant="h6" align="center" my={2}>
            Latest Research
          </Typography>
          <Stack width={"100%"} spacing={2}>
            {data
              .filter((d) => d.newsgroup.title === "Research")
              .splice(0, limit)
              .map((d) => (
                <React.Fragment key={d.id}>
                  <Link to={isLarge ? `/research/view/${d.id}` : "#"}>
                    <Stack spacing={2} direction={"row"} alignItems={"center"}>
                      <DescriptionIcon fontSize="large" />

                      <Stack spacing={1} width="100%">
                        <Stack>
                          <Typography variant="p">{d.title}</Typography>
                        </Stack>
                        <Stack
                          direction={{ xs: "row", md: "column" }}
                          justifyContent={"space-between"}
                          width="100%"
                        >
                          <TimeStamp data={d.created_at} />
                          <Stack alignItems="flex-end">
                            <Link to={`/research/print/${d.id}`}>
                              <Button>Download</Button>
                            </Link>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Link>
                  <Divider />
                </React.Fragment>
              ))}
          </Stack>
          <Button color="white" onClick={() => setlimit(limit + 5)}>
            more research
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

function DesktopCardFull({ d }) {
  return (
    <Stack
      borderRadius={"12px"}
      overflow={"hidden"}
      sx={{
        background: "rgba(255, 255, 255, 0.08)",
      }}
    >
      <Stack width={"100%"} flexShrink={0} position={"relative"}>
        <img
          src={process.env.REACT_APP_ASSET_BASE_URL + "uploads/" + d.imgcover}
          className="img-cover"
          alt=""
        />
        <Stack
          position={"absolute"}
          bottom={"0"}
          p={3}
          sx={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 23.21%, rgba(0, 0, 0, 0.71) 100%)",
          }}
        >
          <Stack
            sx={{
              background:
                "linear-gradient(90.46deg, #46389B 2.01%, rgba(51, 40, 108, 0) 99.56%)",
            }}
          >
            <Typography variant="p">INVESTMENT NEWS</Typography>
          </Stack>
          <Typography variant="h5">{d.title}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

function DesktopCardSmall({ d }) {
  return (
    <Stack
      borderRadius={"12px"}
      overflow={"hidden"}
      sx={{
        background: "rgba(255, 255, 255, 0.08)",
      }}
      direction={"row"}
      p={3}
      spacing={3}
    >
      <Stack width={"84px"} flexShrink={0} className="center">
        <Stack maxHeight={"60px"} width={"100%"}>
          <img
            src={process.env.REACT_APP_ASSET_BASE_URL + "uploads/" + d.imgcover}
            className="img-cover"
            alt=""
          />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="caption">{d.topic}</Typography>
        <Typography variant="body2">{d.title}</Typography>

        <TimeStamp data={d.created_at} />
      </Stack>
    </Stack>
  );
}

function MobileLayout({ data }) {
  const [active, setactive] = useState(data[0]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack
          borderRadius={"12px"}
          overflow={"hidden"}
          sx={{
            background: "rgba(255, 255, 255, 0.08)",
          }}
          mb={3}
        >
          <Stack width={"100%"} flexShrink={0} position={"relative"}>
            <img
              src={
                process.env.REACT_APP_ASSET_BASE_URL +
                "uploads/" +
                active.imgcover
              }
              className="img-cover"
              alt=""
            />
            <Stack
              position={"absolute"}
              bottom={"0"}
              p={3}
              sx={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 23.21%, rgba(0, 0, 0, 0.71) 100%)",
              }}
            >
              <Stack
                sx={{
                  background:
                    "linear-gradient(90.46deg, #46389B 2.01%, rgba(51, 40, 108, 0) 99.56%)",
                }}
              >
                <Typography variant="p">INVESTMENT NEWS</Typography>
              </Stack>
              <Typography variant="h4">{active.title}</Typography>
            </Stack>
          </Stack>
          <Stack p={3} spacing={3}>
            <TimeStamp data={active.created_at} size={"p"} />
            <Stack
              height={"100%"}
              pr={2}
              dangerouslySetInnerHTML={{ __html: active.content }}
            ></Stack>
            <Stack alignItems={"flex-end"}>
              <Stack width={"100px"}>
                <Link to={`/news/view/${active.id}`}>
                  <Button color="white">Read More</Button>
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Stack
          width={"100%"}
          spacing={2}
          sx={{ background: "rgba(255, 255, 255, 0.08)" }}
          borderRadius={"12px"}
          p={2}
        >
          {/* <Typography variant="h6" align="center">
            Trending
          </Typography> */}
          <Stack width={"100%"} spacing={2}>
            {data
              .filter((d) => d.newsgroup.title === "Research")
              .map((d) => (
                <React.Fragment key={d.id}>
                  <Stack spacing={2} direction={"row"} alignItems={"center"}>
                    <Stack height={"96px"} width={"96px"} flexShrink={0}>
                      <img
                        src={
                          process.env.REACT_APP_ASSET_BASE_URL +
                          "uploads/" +
                          d.imgcover
                        }
                        className="img-cover"
                        alt=""
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <Typography
                        variant="body2"
                        className="f-uppercase"
                        fontWeight={"normal"}
                      >
                        {d.topic}
                      </Typography>
                      <Stack
                        onClick={() => setactive(d, window.scrollTo(0, 0))}
                      >
                        <Typography variant="p">{d.title}</Typography>
                      </Stack>
                      <TimeStamp data={d.created_at} />
                    </Stack>
                  </Stack>
                  <Divider />
                </React.Fragment>
              ))}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

function TimeStamp({ data, size = "caption" }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography variant={size}>{fdate.format3(data)}</Typography>
      <Typography variant={size} ml={"25px"}>
        {fdate.format4(data)}
      </Typography>
    </Stack>
  );
}
