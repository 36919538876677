import { Stack, AppBar } from "@mui/material";

import AppSnackbar from "component/app/snackbar";
import LoadingScreen from "component/app/overlay";

import Footer from "./footer";
import Header from "./header";

import { Outlet } from "react-router-dom";

export default function Layout(props) {
  return (
    <Stack
      direction="column"
      alignItems={"center"}
      minHeight={"100vh"}
      width={"100vw"}
      className="bg-dark1"
      overflow={"hidden"}
    >
      <AppBar color="transparent">
        <Header />
      </AppBar>

      <LoadingScreen />
      <Stack
        flexGrow={1}
        alignItems={"center"}
        direction="column"
        width={"100vw"}
        maxWidth={"1024px"}
        px={2}
        pt={"96px"}
      >
        <Outlet />
      </Stack>
      <AppSnackbar />
      <Footer />
    </Stack>
  );
}
