import React from "react";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export default function App(props) {
  return (
    <Stack
      direction="column"
      width={"100vw"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100vh"}
    >
      <Typography variant="h4">404. Not Found</Typography>
      <Button href="/" variant="text">
        back to homepage
      </Button>
    </Stack>
  );
}
