import { Button, Stack, Typography } from "@mui/material";
import { UseStreamGet } from "model/stream";
import { useParams } from "react-router-dom";

import AddComment from "./addComment";

import { Link } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { fdate } from "component/utils/formating";
import ForumIcon from "@mui/icons-material/Forum";

export default function App(props) {
  let { id } = useParams();
  const { stream, refetch } = UseStreamGet({ id: id });

  if (!stream) return "";

  return (
    <Stack
      direction={"column"}
      width={"100vw"}
      maxWidth={"720px"}
      minHeight={"60vh"}
      my={3}
      px={2}
      spacing={2}
    >
      <Stack direction={"row"} spacing={3} width={"100%"} alignItems={"center"}>
        <Link to="/stream" className="center">
          <ArrowBackIcon fontSize="large" />
        </Link>
        <Typography variant="h4">{stream.title}</Typography>
      </Stack>

      {stream.comments && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          maxWidth={"400px"}
        >
          <Typography variant="caption">
            {fdate.format3(stream.created_at)}
          </Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <ForumIcon fontSize="small" />
            <Typography variant="caption">{stream.comments.length}</Typography>
          </Stack>
          <Typography variant="caption">{stream.user.name}</Typography>
        </Stack>
      )}

      <Stack spacing={2}>
        {stream.comments &&
          stream.comments.map((d) => <Comment d={d} key={d.id} />)}
      </Stack>

      <AddComment streamid={stream.id} hasSubmit={() => refetch()} />
    </Stack>
  );
}

function Comment({ d }) {
  return (
    <Stack
      sx={{ background: "rgba(255, 255, 255, 0.08)" }}
      p={2}
      borderRadius={"12px"}
      spacing={2}
    >
      {console.log(d)}
      <Typography variant="p">{d.user.name}</Typography>
      <Typography variant="p">{d.content}</Typography>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography variant="caption">{fdate.format3(d.created_at)}</Typography>
        <Typography variant="caption" ml={"25px"}>
          {fdate.format4(d.created_at)}
        </Typography>
      </Stack>
    </Stack>
  );
}
