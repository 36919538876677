import React, { useState } from "react";

import {
  Stack,
  Box,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Drawer,
  Collapse,
  Popover,
  ListItemButton,
} from "@mui/material";
import { Link } from "react-router-dom";

import bpotImg from "assets/img/bpot.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import { sites } from "view/layout/nav";
import Typography from "@mui/material/Typography";

import Context from "provider/context";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ButtonPink from "component/formHelper/buttonPink";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function App(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSub, setopenSub] = React.useState([]);
  const open = Boolean(anchorEl);
  const { user } = React.useContext(Context);

  const handleClick = (event, subMenu) => {
    setAnchorEl(event.currentTarget);
    setopenSub(subMenu);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setopenSub([]);
  };

  return (
    <Stack
      width={"100%"}
      height={"96px"}
      className="bg-dark"
      alignItems={"center"}
      justifyContent={"space-between"}
      px={3}
      direction={"row"}
    >
      <Box height={"64px"}>
        <img src={bpotImg} alt="" />
      </Box>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        display={{ xs: "none", md: "flex" }}
        spacing={4}
        flexGrow={1}
        px={"64px"}
      >
        {sites.map((d, ix) => (
          <Link to={d.path} key={ix}>
            {d.path !== "#" && (
              <Typography className="f-capitalize f-white">{d.name}</Typography>
            )}
            {d.path === "#" && (
              <>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography
                    className="f-capitalize"
                    onClick={(e) => handleClick(e, d.sub)}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    {d.name}
                  </Typography>
                  <ArrowDropDownIcon />
                </Stack>
                <MenuChild
                  anchor={anchorEl}
                  isClosed={handleClose}
                  child={openSub}
                />
              </>
            )}
          </Link>
        ))}
      </Stack>
      <MobileMenuButton />
      <DesktopMenuButton />
    </Stack>
  );
}

function MenuChild({ anchor, isClosed, child }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSub, setopenSub] = React.useState([]);

  const handleClick = (event, subMenu) => {
    setAnchorEl(event.currentTarget);
    setopenSub(subMenu);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setopenSub([]);
    isClosed(true);
  };

  return (
    <Popover
      open={anchor ? true : false}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {child.map((d, ix) => (
        <React.Fragment key={ix}>
          {d.path !== "#" && (
            <Link to={d.path}>
              <MenuItem sx={{ p: 2 }} onClick={isClosed}>
                {d.name}
              </MenuItem>
            </Link>
          )}
          {d.path === "#" && (
            <Link to="#">
              <MenuItem sx={{ p: 2 }} onClick={(e) => handleClick(e, d.sub)}>
                {d.name}
              </MenuItem>
              <MenuChildNest
                anchor={anchorEl}
                isClosed={handleClose}
                child={openSub}
              />
            </Link>
          )}
        </React.Fragment>
      ))}
    </Popover>
  );
}

function MenuChildNest({ anchor, isClosed, child }) {
  return (
    <Popover
      open={anchor ? true : false}
      anchorEl={anchor}
      onClose={() => isClosed(true)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {child.map((d, ix) => (
        <Link to={d.path} key={ix}>
          <MenuItem sx={{ p: 2 }} onClick={isClosed}>
            {d.name}
          </MenuItem>
        </Link>
      ))}
    </Popover>
  );
}

function MobileMenuButton() {
  const [open, setopen] = useState(false);
  const [subopen, setsubopen] = useState("");
  const { user } = React.useContext(Context);

  const handleClose = () => setopen(false);

  return (
    <Box display={{ xs: "block", sm: "none" }}>
      <IconButton onClick={() => setopen(!open)}>
        <MenuIcon className="f-white" />
      </IconButton>
      <Drawer anchor={"right"} open={open} onClose={() => setopen(false)}>
        <Stack width={"90vw"} py={4}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            px={3}
          >
            <Box width={150}>
              <img src={bpotImg} alt="" className="img-contain" />
            </Box>
            <Box>
              <IconButton
                aria-label="close"
                size="large"
                onClick={() => setopen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Stack>

          <Stack direction={"column"} spacing={2}>
            {sites.map((d, ix) => (
              <div key={ix}>
                {d.path !== "#" && (
                  <ListItemLink href={d.path}>
                    <ListItemText
                      primary={d.name}
                      sx={{ paddingLeft: 4 }}
                      onClick={() => setopen(false, handleClose)}
                    />
                  </ListItemLink>
                )}
                {d.path === "#" && (
                  <>
                    <ListItemButton
                      onClick={() =>
                        setsubopen(subopen === d.name ? "" : d.name)
                      }
                      sx={{ paddingLeft: 4 }}
                    >
                      <ListItemText primary={d.name} />
                      {subopen === d.name ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                    <Collapse
                      in={subopen === d.name}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Stack spacing={2} pl={3} pt={1}>
                        {d.sub.map((e, ix) => (
                          <ListItemLink
                            href={e.path}
                            key={ix}
                            onClick={handleClose}
                          >
                            <ListItemText
                              primary={e.name}
                              sx={{ paddingLeft: 5 }}
                            />
                          </ListItemLink>
                        ))}
                      </Stack>
                    </Collapse>
                  </>
                )}
              </div>
            ))}
          </Stack>
          <Stack px={4} mt={2} spacing={2}>
            {!user.name && (
              <ListItemLink href={"/signin"} onClick={handleClose}>
                <ButtonPink fullWidth startIcon={<AccountCircleIcon />}>
                  Signin
                </ButtonPink>
              </ListItemLink>
            )}
            {user.name && (
              <>
                <ListItemLink href="user/profile" onClick={handleClose}>
                  <ListItemText primary={"Profile"} />
                </ListItemLink>
                <ListItemLink href={"/logout"} onClick={handleClose}>
                  <ButtonPink fullWidth startIcon={<AccountCircleIcon />}>
                    Logout
                  </ButtonPink>
                </ListItemLink>
              </>
            )}
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  );
}

function DesktopMenuButton() {
  const { user } = React.useContext(Context);
  return (
    <Stack
      display={{ xs: "none", sm: "block" }}
      minWidth={"130px"}
      direction={"row"}
      textAlign={"right"}
    >
      {user.name && <LoggedInMenu />}
      {!user.name && (
        <ButtonPink fullWidth href="/signin" startIcon={<AccountCircleIcon />}>
          login
        </ButtonPink>
      )}
    </Stack>
  );
}

function LoggedInMenu(params) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#ED3941" />
          <stop offset={1} stopColor="#E9489D" />
        </linearGradient>
      </svg>
      <IconButton
        onClick={handleClick}
        size="large"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        bgcolor="red"
      >
        <svg width={0} height={0}>
          <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
            <stop offset={0} stopColor="#ED3941" />
            <stop offset={1} stopColor="#E9489D" />
          </linearGradient>
        </svg>
        <AccountCircleIcon
          fontSize="20px"
          sx={{
            fill: "url(#linearColors)",
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: "140px",
            bgcolor: "white !important",
            color: "black !important",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link to="/user/profile">
          <MenuItem>
            <AccountCircleIcon
              sx={{
                fill: "url(#linearColors)",
              }}
            />
            <Typography variant="p" pl={1}>
              Profile
            </Typography>
          </MenuItem>
        </Link>

        <Link to="/logout">
          <MenuItem>
            <AccountCircleIcon
              sx={{
                fill: "url(#linearColors)",
              }}
            />
            <Typography variant="p" pl={1}>
              Logout
            </Typography>
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
}

function ListItemLink(props) {
  return (
    <Link {...props} to={props.href}>
      {props.children}
    </Link>
  );
}
