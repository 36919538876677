import { useState, useEffect } from "react";
import { Stack, ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const resiko = [
  { name: "Konservatif", val: 0.06 },
  { name: "Moderat", val: 0.1 },
  { name: "Agresif", val: 0.16 },
];

export default function App(props) {
  const [state, setstate] = useState(0.1);

  useEffect(() => {
    props.val(state);
  }, [state]);

  return (
    <Stack direction={"row"} spacing={2}>
      {resiko.map((d) => (
        <ColorButton
          value={d.val}
          selected={d.val === state}
          key={d.name}
          onClick={() => setstate(d.val)}
        >
          {d.name}
        </ColorButton>
      ))}
    </Stack>
  );
}

const ColorButton = styled(ToggleButton)(({ theme }) => ({
  // color: theme.palette.getContrastText(purple[500]),
  color: "#97A8BF",
  background: "none",
  border: "2px solid #97A8BF",
  width: "96px",
  minHeight: "48px",
  fontSize: "10px",
  borderRadius: "8px",

  "&.Mui-selected": {
    border: "none",
    background: "linear-gradient(221.6deg, #4A83EC 0%, #21176E 94.73%);",
  },
}));
