import React, { useState } from "react";

import { Stack, Grid, Box, styled } from "@mui/material";
import Typography from "@mui/material/Typography";

import Chart from "./chart";
import TingkatResiko from "./tingkatResiko";

// import ModalAwal from "./modalAwal";
// import JangkaWaktu from "./jangkaWaktu";
// import InvestasiBulanan from "./investasiBulanan";
import { fcurr } from "component/utils/formating";

import Slider from "@mui/material/Slider";

export default function App(props) {
  const [form, setform] = useState({
    jw: 10,
    ma: 10000000,
    mi: 5000000,
    tr: 0.1,
  });

  function formHandleChange(e, newValue) {
    setform({ ...form, [e.target.name]: newValue });
  }
  return (
    <Stack
      direction={"column"}
      className="f-dark"
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography variant="h4" align="center" fontWeight={"normal"}>
        CALCULATE YOUR <b>INVESTMENT</b>
      </Typography>
      <Grid container spacing={{ xs: 2, md: 5 }} mt={{ xs: 2, md: 5 }}>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Stack height={"90%"} justifyContent={"space-between"} spacing={2}>
            <div>
              <Chart param={form} />
            </div>
            <Stack spacing={2} display={{ xs: "none", md: "flex" }}>
              <Typography variant="p">
                * Tingkat indikasi hasil investasi dibuat berdasarkan rata-rata
                kinerja produk investasi di masa lalu dan bukan jaminan hasil
                investasi yang akan diperoleh
              </Typography>

              <Typography variant="p">
                * PT Bumiputera Sekuritas tidak bertanggung jawab atas tingkat
                keakuratan hasil simulasi. Segala resiko akibat penggunaan hasil
                simulasi adalah sepenuhnya tanggung jawab (calon) investor
              </Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Stack spacing={2}>
            <Stack spacing={5}>
              <Typography variant="body1" className="f-bold">
                Jangka Waktu Investasi
              </Typography>
              <PrettoSlider
                defaultValue={10}
                valueLabelFormat={(value) => <div>{value} Tahun</div>}
                step={5}
                min={1}
                max={50}
                marks={jwMarks}
                valueLabelDisplay="on"
                name="jw"
                value={form.jw}
                onChange={formHandleChange}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body1" pt={2} className="f-bold">
                Tingkat Resiko
              </Typography>
              <TingkatResiko val={(e) => setform({ ...form, tr: e })} />
            </Stack>
            <Stack spacing={5}>
              <Typography variant="body1" pt={2} className="f-bold">
                Modal Awal
              </Typography>
              <PrettoSlider
                valueLabelFormat={(value) => <div>{fcurr.format(value)}</div>}
                defaultValue={30000000}
                step={1000000}
                min={1000000}
                max={100000000}
                marks={maMarks}
                valueLabelDisplay="on"
                name="ma"
                value={form.ma}
                onChange={formHandleChange}
              />
            </Stack>
            <Stack>
              <Typography variant="body1" pt={2} className="f-bold">
                Investasi Bulanan
              </Typography>
              <PrettoSlider
                aria-label="Always visible"
                valueLabelFormat={(value) => <div>{fcurr.format(value)}</div>}
                defaultValue={30000000}
                step={1000000}
                min={1000000}
                max={100000000}
                marks={miMarks}
                valueLabelDisplay="on"
                name="mi"
                value={form.mi}
                onChange={formHandleChange}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Stack spacing={2} display={{ xs: "flex", md: "none" }} pt={3}>
        <Typography variant="caption">
          * Tingkat indikasi hasil investasi dibuat berdasarkan rata-rata
          kinerja produk investasi di masa lalu dan bukan jaminan hasil
          investasi yang akan diperoleh
        </Typography>

        <Typography variant="caption">
          * PT Bumiputera Sekuritas tidak bertanggung jawab atas tingkat
          keakuratan hasil simulasi. Segala resiko akibat penggunaan hasil
          simulasi adalah sepenuhnya tanggung jawab (calon) investor
        </Typography>
      </Stack>
    </Stack>
  );
}

const PrettoSlider = styled(Slider)({
  marginTop: "40px",
  height: "8px",
  color: "#21176E",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-markLabel": {
    color: "#474E58",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#21176E",
    border: "3px solid white",
    boxShadow: "0px 2px 4px rgba(13, 17, 72, 0.5)",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      // boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    color: "#474E58",
    backgroundColor: "white",
    border: "1px solid #97A8BF",
    "&:before": {
      borderRight: "1px solid #97A8BF",
      borderBottom: "1px solid #97A8BF",
    },
  },
});

const jwMarks = [
  {
    value: 0,
    label: (
      <Box position={"absolute"} left={1}>
        1 Tahun
      </Box>
    ),
  },
  {
    value: 50,
    label: (
      <Box position={"absolute"} right={0}>
        50 Tahun
      </Box>
    ),
  },
];

const maMarks = [
  {
    value: 1000000,
    label: (
      <Box position={"absolute"} left={0}>
        Rp 1.000.000
      </Box>
    ),
  },
  {
    value: 100000000,
    label: (
      <Box position={"absolute"} right={0}>
        Rp 100.000.000
      </Box>
    ),
  },
];

const miMarks = [
  {
    value: 1000000,
    label: (
      <Box position={"absolute"} left={0}>
        Rp 1.000.000
      </Box>
    ),
  },
  {
    value: 100000000,
    label: (
      <Box position={"absolute"} right={0}>
        Rp 100.000.000
      </Box>
    ),
  },
];
