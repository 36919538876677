import React, { Suspense, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";

/* eslint-disable import/first */
const Signin = React.lazy(() => import("view/auth/signin"));
const Signup = React.lazy(() => import("view/auth/signup"));
const Signout = React.lazy(() => import("view/auth/signout"));

const Feature = React.lazy(() => import("view/feature"));
import LoadingScreen from "component/app/overlay";

import Layout from "view/layout/main";
import AuthLayout from "view/layout/authLayout";

import Home from "view/home";

import Unverified from "view/auth/unverified";
import EmailVerification from "view/auth/emailVerification";
import ForgetPassword from "view/auth/forgetPassword";
import NewPassword from "view/auth/newPassword";

import Stream from "view/user/stream";
import StreamForm from "view/user/stream/form";
import StreamDetail from "view/user/stream/detail";

const Education = React.lazy(() => import("view/user/education/education"));
const Screener = React.lazy(() => import("view/user/screener"));
const Research = React.lazy(() => import("view/user/research"));
const Calculator = React.lazy(() => import("view/user/calculator"));
const Privacy = React.lazy(() => import("view/privacy"));

// import Education from "view/user/education/education";
// import Screener from "view/user/screener";
// import Research from "view/user/research";
// import Calculator from "view/user/calculator";

import News from "view/user/news/newsIndex";
import NewsRss from "view/user/news/newsRss";

import NewsDetail from "view/user/news/newsDetail";
import NewsDetailRss from "view/user/news/newsDetailRss";

// import ResearchDetail from "view/user/news/researchDetail";
// import ResearchDetailPrint from "view/user/news/researchDetailPrint";

const ResearchDetail = React.lazy(() =>
  import("view/user/news/researchDetail")
);
const ResearchDetailPrint = React.lazy(() =>
  import("view/user/news/researchDetailPrint")
);

import ProfileView from "view/user/profile/view";
import ProfileEdit from "view/user/profile/edit";

import Glossarium from "view/user/glossarium";

import Eform from "view/user/oao";

// import Testpdf from "view/user/testpdf";
import Test from "view/test/test";

import V404 from "view/404";
import V500 from "view/500";

function PrivateRoute() {
  const token = localStorage.getItem("AuthToken");
  let location = useLocation();

  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} />
  );
}

export default function App(props) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="signin" element={<Signin />} />
            <Route path="unverified" element={<Unverified />} />
            <Route path="signup" element={<Signup />} />
            <Route
              path="emailverification/:id"
              element={<EmailVerification />}
            />
            <Route path="forgetpassword" element={<ForgetPassword />} />
            <Route path="newpassword/:resettoken" element={<NewPassword />} />
          </Route>

          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="test" element={<Test />} />

            <Route path="features" element={<Feature />} />

            <Route path="education" element={<Education />} />
            <Route path="glossarium" element={<Glossarium />} />
            <Route path="eform" element={<Eform />} />
            <Route path="calculator">
              <Route index element={<Calculator />} />
              <Route path=":id" element={<Calculator />} />
            </Route>

            <Route element={<PrivateRoute />}>
              <Route path="screener" element={<Screener />} />
              <Route path="riset" element={<Research />} />

              <Route path="news">
                <Route index element={<News />} />
                <Route path="view/:id" element={<NewsDetail />} />
                <Route path="viewrss" element={<NewsDetailRss />} />

                <Route path="rss" element={<NewsRss />} />
              </Route>

              <Route path="research">
                {/* <Route index element={<News />} /> */}
                <Route path="view/:id" element={<ResearchDetail />} />
                <Route path="print/:id" element={<ResearchDetailPrint />} />
              </Route>

              <Route path="stream">
                <Route index element={<Stream />} />
                <Route path="add" element={<StreamForm />} />
                <Route path=":id" element={<StreamDetail />} />
              </Route>

              <Route path="user">
                <Route path="profile" element={<ProfileView />} />
                <Route path="edit" element={<ProfileEdit />} />
              </Route>
            </Route>
          </Route>
          <Route path="logout" element={<Signout />} />
          <Route path="error" element={<V500 />} />
          <Route path="*" element={<V404 />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
