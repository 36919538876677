import React from "react";

import {
  TextField,
  Button,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import Layout from "view/layout/main";

// import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Context from "provider/context";
import SendIcon from "@mui/icons-material/Send";

const validationSchema = yup.object({
  content: yup.string("Enter your fullname").required("Fullname is required"),
});

export default function App(props) {
  const { setisLoading } = React.useContext(Context);
  // let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      content: "",
    },
    validationSchema: validationSchema,
    onSubmit: (payload) => {
      setisLoading(true);
      axios
        .post(process.env.REACT_APP_API_BASE_URL + "stream/addcomment", {
          ...payload,
          streams_id: props.streamid,
        })
        .then((res) => {
          setisLoading(false);
          if (res.status === 200) {
            // navigate("/stream", { replace: true });
            props.hasSubmit(true);
            formik.resetForm();
          }
        })
        .catch((error) => {
          setisLoading(false);
          console.log(error);
        });
    },
  });

  return (
    <>
      <Stack
        direction={"column"}
        width={"100%"}
        alignItems={"center"}
        spacing={2}
      >
        <TextField
          fullWidth
          name="content"
          placeholder="Reply Stream"
          value={formik.values.content}
          onChange={formik.handleChange}
          error={formik.touched.content && Boolean(formik.errors.content)}
          helperText={formik.touched.content && formik.errors.content}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="sent"
                  onClick={formik.handleSubmit}
                  edge="end"
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </>
  );
}
