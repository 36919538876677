import React, { useContext } from "react";
import Context from "provider/context";
import { Box, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

export default function App(props) {
  const { snack, setSnack } = useContext(Context);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={3000}
      open={snack.isOpen}
      onClose={() => {
        setSnack({
          ...snack,
          isOpen: false,
        });
      }}
    >
      <Box minWidth="300px">
        <MuiAlert elevation={3} severity={snack.severity}>
          {snack.msg}
        </MuiAlert>
      </Box>
    </Snackbar>
  );
}
