import { Grid, Stack, IconButton } from "@mui/material";
import { Outlet } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import authIMg from "assets/img/auth.jpg";
import LoadingScreen from "component/app/overlay";

export default function App(props) {
  return (
    <Grid container width={"100vw"} height="100vh" className="bg-dark1">
      <Grid
        item
        xs={12}
        md={6}
        className="center"
        position={"relative"}
        minHeight="100vh"
      >
        <Stack position={"absolute"} top={0} p={3} left={0}>
          <Link to="/">
            <IconButton size="large">
              <HomeIcon />
            </IconButton>
          </Link>
        </Stack>
        <Stack width={"100%"} p={5}>
          <Outlet />
        </Stack>
      </Grid>
      <Grid
        item
        xs={0}
        md={6}
        display={{ xs: "none", lg: "flex" }}
        height="100vh"
      >
        <img src={authIMg} alt="" className="img-cover" />
      </Grid>
      <LoadingScreen />
    </Grid>
  );
}
