import React, { useState } from "react";

import { Stack, Button } from "@mui/material";
import Typography from "@mui/material/Typography";

import Context from "provider/context";
import axios from "axios";

import { useLocation } from "react-router-dom";

export default function App(props) {
  const [hasResend, sethasResend] = useState(false);
  const { setisLoading } = React.useContext(Context);

  let location = useLocation();
  let refemail = location.state ? location.state.email : "/";

  function resend() {
    setisLoading(true);

    axios
      .post(process.env.REACT_APP_API_BASE_URL + "auth/reverification", {
        email: refemail,
      })
      .then((res) => {
        setisLoading(false);
        if (res.status === 200) {
          sethasResend(true);
        }
      })
      .catch((error) => {
        // setisLoading(false);
        // seterror("incorrect email or password");
      });
  }

  return (
    <Stack
      flexGrow={1}
      justifyContent={"center"}
      alignItems={"center"}
      minHeight={"calc(100vh - 96px)"}
    >
      <Typography textAlign={"center"}>
        We have sent an email verification to <br />
        {refemail}
      </Typography>
      <Typography textAlign={"center"} mt={2}>
        Please check your email in order to continue the signup process
      </Typography>

      {!hasResend && (
        <Button onClick={resend}>Resend Verificantion email</Button>
      )}
    </Stack>
  );
}
