import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { fdate } from "component/utils/formating";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    y: {
      grid: {
        display: false,
      },
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, values) {
          return value / 1000000 + " M";
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      display: false,
    },
    legendCallback: (e) => console.log(e),

    title: {
      display: true,
      // text: "Chart.js Line Chart",
    },
  },
};

// const investmentType = [
//   { name: "Deposito", color: "#4ADDDE" },
//   { name: "SBN", color: "#FF7E7E" },
//   { name: "RD Pendapatan Tetap", color: "#2287C9" },
// ];

const years = function (startYear, endYear) {
  let currentYear = new Date().getFullYear();
  let years = [];
  startYear = startYear || currentYear;

  while (startYear <= endYear) {
    years.push(startYear++);
  }
  return years;
};

function dataCalc(label, param) {
  let rd = [];
  const rate = 1 + param.tr / 12;
  let ma = 0;

  // console.log(label);
  for (let index = 1; index <= 12 * (label.length - 1); index++) {
    if (ma === 0) {
      ma = param.ma * rate;
      rd.push(ma);
    } else ma = (ma + param.mi) * rate;

    if (index % 12 === 0) rd.push(ma);
  }

  return rd;
}

export default function App(props) {
  const [dataState, setdataState] = useState(null);

  React.useEffect(() => {
    const labels = years(fdate.todayYear, fdate.todayYear + props.param.jw);
    setdataState({
      labels,
      datasets: [
        {
          label: "Your Investment",
          data: dataCalc(labels, props.param),
          borderColor: "#4ADDDE",
        },
      ],
    });
  }, [props.param]);

  return (
    <>
      {/* {console.log(dataState)} */}
      {dataState && <Line options={options} data={dataState} />}
      {/* <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
        {investmentType.map((d) => (
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Stack width={"8px"} height={"8px"} bgcolor={d.color} />
            <p>{d.name}</p>
          </Stack>
        ))}
      </Stack> */}
    </>
  );
}
