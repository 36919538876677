import React from "react";
import Stack from "@mui/material/Stack";

export default function SignIn(props) {
  return (
    <Stack
      direction="column"
      width={"100vw"}
      maxWidth={"1280px"}
      p={{ xs: 2, md: 3 }}
      className="center"
    >
      <Stack width={"100vw"} maxWidth={{ xs: "100%", md: "840px" }}>
        {props.headerElement}
      </Stack>
      <Stack
        sx={{
          background: "rgba(255, 255, 255, 0.08)",
        }}
        width={"840px"}
        maxWidth={{ xs: "100%", md: "840px" }}
        height={{ xs: "calc(100vh - 128px)", md: "100%" }}
        minHeight={"480px"}
        borderRadius={"32px"}
        mx={4}
        px={{ xs: 2, md: "120px" }}
        alignItems={"center"}
      >
        <Stack width={"100%"} spacing={2} pt={"60px"}>
          {props.children}
        </Stack>
      </Stack>
    </Stack>
  );
}
