import { Button, Stack, Typography } from "@mui/material";
// import Layout from "view/layout/main";
import { UseStreamGet } from "model/stream";
import { Link } from "react-router-dom";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CommentIcon from "@mui/icons-material/Comment";
import ForumIcon from "@mui/icons-material/Forum";

import { fdate } from "component/utils/formating";
export default function App(props) {
  const { stream } = UseStreamGet();

  return (
    <Stack
      width={"100%"}
      pt={2}
      minHeight={"60vh"}
      spacing={3}
      pb={5}
      maxWidth={"720px"}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h4">Stream</Typography>
        <Link to="/stream/add">
          <AddCommentIcon />
        </Link>
      </Stack>

      <Stack spacing={3}>
        {stream.map((d) => (
          <Card d={d} />
        ))}
      </Stack>

      {!stream.length && <Empty />}
    </Stack>
  );
}

function Empty() {
  return (
    <Stack className="center" flexGrow={1}>
      <CommentIcon sx={{ fontSize: "132px", color: "#7B71B1" }} />
      <Typography variant="subtitle1" color={"#7B71B1"}>
        There is no stream, please create new stream
      </Typography>
    </Stack>
  );
}

function Card({ d }) {
  return (
    <Link to={`/stream/${d.id}`}>
      <Stack
        direction={"column"}
        key={d.id}
        sx={{ background: "rgba(255, 255, 255, 0.08)" }}
        p={2}
        borderRadius={"12px"}
      >
        <Stack direction={"column"} spacing={1}>
          {console.log(d)}
          <Typography variant="h5">{d.title}</Typography>
          <Typography variant="body2">{d.content}</Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={{ xs: "100%", md: "50%" }}
          >
            <Typography variant="caption">
              {fdate.format3(d.created_at)}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <ForumIcon fontSize="small" />
              <Typography variant="caption">{d.comments.length}</Typography>
            </Stack>
            <Typography variant="caption">{d.user.name}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Link>
  );
}
