import { Button, Stack, Grid, Box } from "@mui/material";

import Typography from "@mui/material/Typography";

// import landingMobileImg from "assets/img/landing/landingmobile.svg";
// import landingDekstopImg from "assets/img/landing/landingDekstop.svg";
import landingbgmobileImg2 from "assets/img/landing/bgmobile.png";
import landingDekstopImg2 from "assets/img/landing/bgdekstop.png";
import devicesImg from "assets/img/landing/devices.png";

import { Link } from "react-router-dom";

import playstoreImg from "assets/img/landing/playstore.png";
import appstoreImg from "assets/img/landing/appstore.png";
import micstoreImg from "assets/img/landing/micstore.png";

// import AksesibelIcon from "assets/img/landing/why/aitrading.svg";
// import KenyamananIcon from "assets/img/landing/1.svg";
// import HematBiayaIcon from "assets/img/landing/1.svg";

import aitradingIc from "assets/img/landing/why/aitrading.svg";
import crossplatformIc from "assets/img/landing/why/crossplatform.svg";
import lowerfeeIc from "assets/img/landing/why/lowerfee.svg";
import morefeatureIc from "assets/img/landing/why/morefeature.svg";
import secureIc from "assets/img/landing/why/secure.svg";
import userfriendlyIc from "assets/img/landing/why/userfriendly.svg";

import ctabgImg from "assets/img/landing/ctabg.svg";

// import noImg from "assets/img/noimg.svg";

import PreviewSaham from "component/app/previewSahamIframe";
import SimulasiSaham from "component/simulasiSaham";

import ButtonPink from "component/formHelper/buttonPink";

// import { TextField, MenuItem } from "@mui/material";

export default function App(props) {
  return (
    <>
      <LandingHome />
      <Box width={"100vw"} position={"relative"}>
        <Stack
          width={"100vw"}
          className="center"
          height={{ xs: "auto", md: "auto" }}
          zIndex={12}
          position={{ sx: "relative", md: "absolute" }}
          top={{ sx: 0, md: "-72px" }}
        >
          <PreviewSaham />
          <Stack
            alignItems={"flex-end"}
            width={"100%"}
            px={2}
            position="absolute"
            marginTop={"-92px"}
            zIndex={10}
            fontSize={12}
          >
            <div className="tradingview-widget-copyright">
              <a
                href="https://www.tradingview.com/markets/"
                rel="noopener"
                target="_blank"
              >
                <span className="blue-text">Markets</span>
              </a>
              by TradingView
            </div>
          </Stack>
        </Stack>
      </Box>
      <AlasanSection />
      <SimulasiSection />
      <RegAction />
    </>
  );
}

function LandingHome() {
  return (
    <Stack height={"100vh"} bgcolor={"#140735"} width={"100vw"} mt={"-96px"}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        width={"100vw"}
        height={"100vh"}
        alignItems={"center"}
        justifyContent={"center"}
        zIndex={10}
      >
        <Stack
          alignItems={{ xs: "center", md: "flex-start" }}
          justifyContent={{ xs: "space-between", md: "center" }}
          width={"100%"}
          maxWidth={"1280px"}
          spacing={5}
          height={"100%"}
          p={5}
          position={"relative"}
        >
          <Stack
            direction={"column"}
            spacing={5}
            justifyContent={{ xs: "center", md: "flex-start" }}
            alignItems={{ xs: "center", md: "flex-start" }}
            pt={{ xs: "20vh", md: 0 }}
          >
            <Typography variant="h3">#BetterwithBPOT</Typography>
            <Box
              width={{ xs: "80%", md: "60%" }}
              textAlign={{ xs: "center", md: "left" }}
            >
              <Typography variant="h5">
                More Benefit, More Fun, More Easy Try BPOT, Download Now.
              </Typography>
            </Box>
          </Stack>

          <Stack direction={"row"} spacing={2} bottom={0}>
            <a
              href="https://play.google.com/store/apps/details?id=com.fgsinfotama.skytrade.bumiputera"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box height={"47px"}>
                <img src={playstoreImg} className="img-contain" alt="" />
              </Box>
            </a>

            <a
              href="https://apps.apple.com/id/app/bpot/id1495503581"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Stack height={"47px"}>
                <img src={appstoreImg} className="img-contain" alt="" />
              </Stack>
            </a>

            <a
              href="https://bpot.bumiputerasekuritas.co.id/bumiputera.jnlp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box height={"47px"} display={{ xs: "none", md: "block" }}>
                <img src={micstoreImg} className="img-contain" alt="" />
              </Box>
            </a>
          </Stack>
        </Stack>
      </Stack>

      <Box
        width={"100vw"}
        height={"100vh"}
        position={"absolute"}
        id="bg-main"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        overflow={"hidden"}
      >
        <Stack
          display={{ xs: "block", md: "none" }}
          zIndex={1}
          position={"absolute"}
          width={"100%"}
          right={"0"}
          bottom={"-10px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
        >
          <img src={landingbgmobileImg2} alt="" className="img-contain" />
        </Stack>
        <Stack
          display={{ xs: "none", md: "block" }}
          zIndex={1}
          position={"absolute"}
          right={0}
          bottom={"-10px"}
        >
          <img src={landingDekstopImg2} alt="" className="img-cover" />
        </Stack>

        <Stack
          width={"100vw"}
          maxWidth={"1280px"}
          height={"100vh"}
          position={"absolute"}
          zIndex={5}
        >
          <Stack
            width={"100vw"}
            height={"100%"}
            maxWidth={"640px"}
            position={"absolute"}
            justifyContent={"center"}
            right={0}
            bottom={{ xs: "-150px", md: "unset" }}
            p={4}
          >
            <img src={devicesImg} className="img-contain" alt="" />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}

function AlasanSection() {
  const data = [
    {
      title: "Lower Fee & Commission",
      icon: lowerfeeIc,
    },
    {
      title: "User Friendly",
      icon: userfriendlyIc,
    },
    {
      title: "More Features",
      icon: morefeatureIc,
    },
    {
      title: "Secure",
      icon: secureIc,
    },
    {
      title: "Cross-Platform Trading",
      icon: crossplatformIc,
    },
    {
      title: "AI Trading",
      icon: aitradingIc,
    },
  ];

  return (
    <Stack
      py={{ xs: "24px", md: "80px" }}
      alignItems={"center"}
      justifyContent="center"
      width={"100vw"}
      sx={{
        background:
          "linear-gradient(32.48deg, #201B93 -4.82%, #221240 87.44%, #221240 87.44%);",
      }}
    >
      <Stack direction={"column"} maxWidth={"1280px"}>
        <Typography variant="h4" align="center" fontWeight={"normal"}>
          WHY CHOOSE US
        </Typography>

        <Grid container spacing={3} px={3} mt={{ xs: 2, md: "60px" }}>
          {data.map((d) => (
            <Grid item xs={6} md={4} key={d.title}>
              <Stack
                width={"100%"}
                height={"180px"}
                direction={"column"}
                spacing={3}
                padding={1}
                bgcolor={
                  "rgba(216, 216, 216, 0.119197);rgba(216, 216, 216, 0.119197);"
                }
                borderRadius={"32px"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Stack width={"48px"} height={"48px"}>
                  <img src={d.icon} className="img-contain" alt="" />
                </Stack>
                <Stack
                  direction={"column"}
                  spacing={1}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  textAlign={"center"}
                >
                  <Typography variant="p" className=" f-bold">
                    {d.title}
                  </Typography>

                  <Typography variant="caption">{d.body}</Typography>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}

function SimulasiSection() {
  return (
    <Stack
      sx={{
        background:
          "linear-gradient(63.76deg, rgba(255, 255, 255, 0.0001) 49.76%, #D6D6D6 100%), linear-gradient(42deg, #C2C8FF -25.78%, #FFFFFF 22.83%)",
      }}
      width={"100vw"}
      justifyContent={"space-between"}
      alignItems={"center"}
      px={2}
      py={{ xs: "24px", md: "80px" }}
    >
      <Stack width={"100%"} maxWidth={"1280px"} position={"relative"}>
        <SimulasiSaham />
      </Stack>
    </Stack>
  );
}

function RegAction() {
  return (
    <Stack
      sx={{
        background:
          "linear-gradient(32.48deg, #201B93 -4.82%, #221240 87.44%, #221240 87.44%)",
      }}
      width={"100vw"}
      minHeight={{ xs: 440, md: 700 }}
      justifyContent={"space-between"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"relative"}
      p={2}
    >
      <Stack
        position={"absolute"}
        height="100%"
        width={"100vw"}
        top={0}
        right={0}
        zIndex={0}
      >
        <Stack
          position={"absolute"}
          width={{ xs: "50vw", md: 334 }}
          right={0}
          bottom={0}
          zIndex={0}
        >
          <img src={ctabgImg} alt="" className="img-contain" />
        </Stack>
      </Stack>

      <Stack
        width={"100%"}
        maxWidth={"1280px"}
        position={"relative"}
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
      >
        <Typography
          variant="h2"
          className="f-white"
          textAlign={{ xs: "left", md: "center" }}
          zIndex={1}
          width={{ xs: "90%", md: "60%" }}
          fontWeight={700}
        >
          Join the Community of Our Experienced Traders
        </Typography>
        <Grid
          container
          spacing={3}
          mt={{ xs: "32px", md: "100px" }}
          maxWidth={350}
        >
          <Grid item xs={6}>
            <Link to="/signin">
              <Button variant="outlined" color="white" size="small" fullWidth>
                Login to BPOT
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/signup">
              <ButtonPink
                size="large"
                variant="outlined"
                size="small"
                fullWidth
              >
                Register now
              </ButtonPink>
            </Link>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
