import React, { useState } from "react";

import Context from "provider/context";
import useAxios from "axios-hooks";
import {
  Typography,
  Stack,
  TextField,
  InputAdornment,
  MenuItem,
  Modal,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const alphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export default function App(props) {
  const { setisLoading } = React.useContext(Context);
  const [search, setsearch] = useState("");
  const [first, setfirst] = useState();
  const [modalopen, setmodalopen] = useState(false);
  const [dataactived, setdataactived] = useState();

  const [{ data, loading }] = useAxios(
    {
      url: process.env.REACT_APP_API_BASE_URL + "glossarium",
      method: "get",
    },
    { useCache: false }
  );

  React.useEffect(() => {
    loading && setisLoading(true);
    !loading && setisLoading(false);
  });

  function searchFilter(d) {
    return d.title.toLowerCase().includes(search.toLowerCase());
  }

  if (!data) return "";

  return (
    <Stack spacing={2} width={"100%"} minHeight={"70ht"} my={3} pb={5}>
      <Typography variant="h4" pt={3} align="left" width={"100%"}>
        Glossarium
      </Typography>
      <Stack className="bg-glass" borderRadius={"12px"} p={3} spacing={3}>
        <TextField
          placeholder="Search Glossary"
          value={search}
          fullWidth
          onChange={(e) => setsearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
          {alphabet.slice(0, 13).map((d) => (
            <MenuItem
              key={d}
              selected={d === first}
              className="center"
              sx={{ borderRadius: "8px", width: "100%", padding: "4px 0px" }}
              onClick={() => (first == d ? setfirst() : setfirst(d))}
            >
              <Typography variant="body2">{d.toUpperCase()}</Typography>
            </MenuItem>
          ))}
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
          {alphabet.slice(13, 26).map((d) => (
            <MenuItem
              key={d}
              selected={d === first}
              className="center"
              sx={{ borderRadius: "8px", width: "100%", padding: "4px 0px" }}
              onClick={() => (first == d ? setfirst() : setfirst(d))}
            >
              <Typography variant="body2">{d.toUpperCase()}</Typography>
            </MenuItem>
          ))}
        </Stack>

        <Stack>
          {data
            .filter((d) => (!first ? d : d.title[0].toLowerCase() === first))
            .filter(searchFilter)
            .map((d, ix) => (
              <Stack
                key={d.id}
                p={2}
                className={ix % 2 == 0 && "bg-glass"}
                onClick={() => setdataactived(d, setmodalopen(true))}
              >
                <Typography variant="p">{d.title}</Typography>
              </Stack>
            ))}
        </Stack>
      </Stack>

      <Modal
        open={modalopen}
        onClose={() => setmodalopen(false)}
        sx={{
          width: "100vw",
          height: "100vh",
          backdropFilter: "blur(10px)",
          padding: 2,
        }}
        className="center"
      >
        {dataactived && (
          <Stack
            width={"100%"}
            maxWidth={"600px"}
            height={"100%"}
            bgcolor={"#473482"}
            spacing={2}
            p={3}
            sx={{
              overflowY: "scroll",
            }}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography id="modal-modal-title" variant="h5">
                {dataactived.title}
              </Typography>
              <IconButton onClick={() => setmodalopen(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography id="modal-modal-title" variant="h5">
              Definition
            </Typography>
            <Typography id="modal-modal-title" variant="body2">
              {dataactived.definition}
            </Typography>
            {dataactived.formula && (
              <>
                <Typography id="modal-modal-title" variant="h5">
                  Formula
                </Typography>
                <Stack border={"1px dashed #FFFEFE"} p={2} borderRadius={"4px"}>
                  <Typography id="modal-modal-title" variant="body2">
                    {dataactived.formula}
                  </Typography>
                </Stack>
              </>
            )}

            {dataactived.explanation && (
              <>
                <Typography id="modal-modal-title" variant="h5">
                  Explanation
                </Typography>

                <Typography id="modal-modal-title" variant="body2">
                  {dataactived.explanation}
                </Typography>
              </>
            )}
          </Stack>
        )}
      </Modal>
    </Stack>
  );
}
