import React from "react";
import ReactDOM from "react-dom/client";
// import ReactDOM from "react-dom";
import "assets/css/app.css";
import AppRoute from "route/route";
import ContextProvider from "provider/contextProvider";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "G-RWKPP47LVC",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.createRoot(document.getElementById("root")).render(
  <ContextProvider>
    <AppRoute />
  </ContextProvider>
);

// ReactDOM.render(
//   <ContextProvider>
//     <AppRoute />
//   </ContextProvider>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
