import React from "react";
import Button from "component/formHelper/buttonGreen";

import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import { InputAdornment, IconButton } from "@mui/material";
import { Stack, Grid } from "@mui/material";

import Context from "provider/context";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import GlassBg from "view/layout/glassBg";

import ContactMailIcon from "@mui/icons-material/ContactMail";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LockIcon from "@mui/icons-material/Lock";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const validationSchema = yup.object({
  name: yup.string("Enter your fullname").required("Fullname is required"),
  phone: yup.string("Enter your fullname").required("Phone is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export default function SignUp() {
  const { setisLoading, user, setUser } = React.useContext(Context);
  const [error, seterror] = React.useState("");
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: user.email,
      password: "",
      name: user.name,
      phone: user.phone,
      username: user.username,
    },
    validationSchema: validationSchema,
    onSubmit: (payload) => {
      setisLoading(true);
      seterror("");

      axios
        .post(process.env.REACT_APP_API_BASE_URL + "auth/edit", payload)
        .then((res) => {
          setisLoading(false);
          if (res.status === 200) {
            setUser(res.data);
            localStorage.setItem("AuthUser", JSON.stringify(res.data));
            localStorage.setItem("AuthToken", res.data.token);
            navigate("/user/profile", { replace: true });
          }
        })
        .catch((error) => {
          setisLoading(false);
          seterror("incorrect email or password");
        });
    },
  });

  return (
    <Stack>
      <GlassBg headerElement={<Header />}>
        <Grid container spacing={3}>
          <Grid item xs={6} order={{ sx: 1, md: 1 }}>
            <TextField
              name="name"
              fullWidth
              placeholder="Full Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ContactMailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} order={{ sx: 2, md: 4 }}>
            <TextField
              fullWidth
              id="email"
              name="email"
              disabled
              placeholder="Email Address"
              autoComplete="new-password"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} order={{ sx: 3, md: 3 }}>
            <TextField
              fullWidth
              autoComplete="new-password"
              name="phone"
              placeholder="Phone"
              type="number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} order={{ sx: 4, md: 2 }}>
            <TextField
              fullWidth
              name="username"
              placeholder="Username"
              type="text"
              disabled
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} order={{ sx: 5, md: 5 }}>
            <TextField
              fullWidth
              autoComplete="new-password"
              id="password"
              name="password"
              placeholder="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Stack pb={5}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Save Profile
          </Button>
        </Stack>
      </GlassBg>
    </Stack>
  );
}

function Header() {
  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"} my={3}>
      <Link to="/user/profile">
        <IconButton>
          <ArrowBackIcon fontSize="large" />
        </IconButton>
      </Link>
      <Typography variant="h3">Edit Profile</Typography>
    </Stack>
  );
}
