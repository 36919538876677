import { Stack, Button } from "@mui/material";

export default function ErrorScreen() {
  return (
    <Stack
      height="100vh"
      width={"100vw"}
      alignItems={"center"}
      justifyContent={"center"}
      direction={"column"}
    >
      <h1>500</h1>
      <div className="">Something Error with your request</div>
      <Button className="f-white" href="/">
        Back to homepage
      </Button>
    </Stack>
  );
}
