import React from "react";

import Context from "provider/context";
import useAxios from "axios-hooks";

export function UseStreamGet(filter) {
  const { setisLoading } = React.useContext(Context);
  const [{ data, loading }, refetch] = useAxios(
    {
      url: process.env.REACT_APP_API_BASE_URL + "stream",
      method: "post",
      data: filter,
    },
    { useCache: false }
  );

  React.useEffect(() => {
    loading && setisLoading(true);
    !loading && setisLoading(false);
  });

  return {
    stream: loading ? [] : data,
    refetch,
  };
}
