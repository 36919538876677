import React, { useState } from "react";
import Context from "provider/context";

import { Stack, Grid, Typography, IconButton } from "@mui/material";
import { Link } from "react-router-dom";

import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SettingsIcon from "@mui/icons-material/Settings";

export default function App(props) {
  const { user } = React.useContext(Context);
  return (
    <Stack width={"100%"} minHeight={"700px"}>
      <Typography variant="h3" my={3}>
        Profile
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack
            className="bg-glass center"
            width={"100%"}
            minHeight={"240px"}
            borderRadius={"12px"}
            position={"relative"}
          >
            <Typography variant="h3">{user.name}</Typography>
            <Typography variant="h5">{user.username}</Typography>
            <Stack position={"absolute"} top={"16px"} right={"16px"}>
              <Link to="/user/edit">
                <IconButton>
                  <SettingsIcon />
                </IconButton>
              </Link>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack spacing={2} minHeight={"240px"}>
            <Stack
              className="bg-glass "
              width={"100%"}
              flexGrow={1}
              borderRadius={"12px"}
              direction={"row"}
              px={4}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <EmailIcon fontSize="large" />
              <Stack ml={2}>
                <Typography variant="p">Email</Typography>
                <Typography variant="subtitle1">{user.email}</Typography>
              </Stack>
            </Stack>

            <Stack
              className="bg-glass "
              width={"100%"}
              flexGrow={1}
              borderRadius={"12px"}
              direction={"row"}
              px={4}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <LocalPhoneIcon fontSize="large" />
              <Stack ml={2}>
                <Typography variant="p">Phone</Typography>
                <Typography variant="subtitle1">{user.phone}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
