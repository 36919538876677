import "./axios.js";

import React, { useState } from "react";
import Context from "./context";
// import { ThemeProvider } from "@mui/material/styles";
import ThemeProvider from "./themeProvider";
// import { StylesProvider } from "@mui/styles";
// import { lightTheme } from "provider/themeRoot";
// import { userInitialState, UserReducer } from "./userReducer";
// import useMediaQuery from "@mui/material/useMediaQuery";

export default function App(props) {
  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem("AuthUser")) || {}
  );
  const [theme, settheme] = React.useState(
    localStorage.getItem("preferred-theme") || "light"
  );
  const [isLoading, setisLoading] = useState(false);
  const [snack, setSnack] = useState({
    isOpen: false,
    isError: false,
    msg: "",
  });

  return (
    <Context.Provider
      value={{
        user,
        setUser,
        isLoading,
        setisLoading,
        snack,
        setSnack,
        theme,
        settheme,
      }}
    >
      {/* <StylesProvider injectFirst></StylesProvider> */}
      <ThemeProvider>{props.children}</ThemeProvider>
    </Context.Provider>
  );
}
