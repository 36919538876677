import { Stack, Grid, Typography, Divider } from "@mui/material";
import { fdate } from "component/utils/formating";

export default function TimeStamp({ data, size = "caption" }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography variant={size}>{fdate.format3(data)}</Typography>
      <Typography variant={size} ml={"25px"}>
        {fdate.format4(data)}
      </Typography>
    </Stack>
  );
}
