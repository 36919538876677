import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "red",
  background: "linear-gradient(229.99deg, #1EDA80 -24.47%, #2395B9 75.82%)",

  "&:hover": {
    background: "linear-gradient(229.99deg, #1EDA80 -24.47%, #2395B9 75.82%)",
  },

  "&.Mui-disabled": {
    background: "grey",
  },
}));

export default ColorButton;
