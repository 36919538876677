import React, { useState } from "react";

import Context from "provider/context";
import useAxios from "axios-hooks";
import { Stack, Grid, Typography, Divider, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fdate } from "component/utils/formating";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";

import { parse } from "rss-to-json";

export default function App(props) {
  const { setisLoading, isLoading } = React.useContext(Context);

  const [data, setdata] = useState([]);

  let u1 = "http://theguardian.com/world/rss";
  let u2 = "http://feeds.a.dj.com/rss/RSSWorldNews.xml";
  let u3 = "http://rss.nytimes.com/services/xml/rss/nyt/World.xml";
  let u4 = "https://feeds.washingtonpost.com/rss/world";
  let u5 = "https://rss.kontan.co.id/news/investasi";
  let u6 = "https://market.bisnis.com/korporasi-emiten/rss";

  React.useEffect(() => {
    async function fetchData() {
      setisLoading(true);
      parse(u6)
        .then((rss) => {
          console.log(rss);
          let temp = data;
          rss.items.map((d, ix) => {
            let payload = {
              id: ix,
              title: d.title,
              content: d.description,
              created_at: d.published,
              topic: d.title,
              link: d.link,
              img: d.media.thumbnail,
            };
            temp.push(payload);
          });
          setdata(temp);
        })
        .then((e) => {
          setisLoading(false);
        });
    }
    fetchData();
  }, [data]);

  if (!data.length || isLoading) return "loding";

  return (
    <Stack width={"100%"} my={3}>
      <Stack direction={"row"} spacing={2} alignItems={"center"} mb={3}>
        <SearchIcon />
        <Typography variant="h4">Latest News {data.length}</Typography>
      </Stack>
      <DesktopLayout data={data} />
    </Stack>
  );
}

export function DesktopLayout({ data }) {
  const [limit, setlimit] = useState(2);
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Stack spacing={3} wid>
      {data.map((d, ix) => (
        <Link to={"/news/viewrss"} state={d} key={d.id}>
          {ix === 0 && <DesktopCardFull d={d} />}
          {ix > 0 && <DesktopCardSmall d={d} />}
        </Link>
      ))}
    </Stack>
  );
}

function DesktopCardFull({ d }) {
  return (
    <Stack
      borderRadius={"12px"}
      overflow={"hidden"}
      sx={{
        background: "rgba(255, 255, 255, 0.08)",
      }}
    >
      <Stack width={"100%"} flexShrink={0} position={"relative"}>
        <img src={d.img ? d.img.url : ""} className="img-cover" alt="" />
        <Stack
          position={"absolute"}
          bottom={"0"}
          p={3}
          sx={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 23.21%, rgba(0, 0, 0, 0.71) 100%)",
          }}
        >
          <Stack
            sx={{
              background:
                "linear-gradient(90.46deg, #46389B 2.01%, rgba(51, 40, 108, 0) 99.56%)",
            }}
          >
            <Typography variant="p">INVESTMENT NEWS</Typography>
          </Stack>
          <Typography variant="h5">{d.title}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

function DesktopCardSmall({ d }) {
  return (
    <Stack
      borderRadius={"12px"}
      overflow={"hidden"}
      sx={{
        background: "rgba(255, 255, 255, 0.08)",
      }}
      direction={"row"}
      p={3}
      spacing={3}
    >
      <Stack width={"84px"} flexShrink={0} className="center">
        <Stack maxHeight={"60px"} width={"100%"}>
          <img src={d.img ? d.img.url : ""} className="img-cover" alt="" />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="caption">{d.topic}</Typography>
        <Typography variant="body2">{d.title}</Typography>

        <TimeStamp data={d.created_at} />
      </Stack>
    </Stack>
  );
}

function TimeStamp({ data, size = "caption" }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography variant={size}>{fdate.format3(data)}</Typography>
      <Typography variant={size} ml={"25px"}>
        {fdate.format4(data)}
      </Typography>
    </Stack>
  );
}
