import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";

import Context from "provider/context";

import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useParams } from "react-router-dom";

import GlassBg from "view/layout/glassBg";

const validationSchema = yup.object({
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export default function App(params) {
  const [hasReset, sethasReset] = useState();
  return (
    <Stack>
      {!hasReset && <NewPassword hasSubmit={() => sethasReset(true)} />}
      {hasReset && <NewPasswordSubmited />}
    </Stack>
  );
}

function NewPasswordSubmited({ email }) {
  return (
    <Stack
      display={"flex"}
      height={"100vh"}
      flexGrow={1}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
      width={"100%"}
    >
      <Typography textAlign={"center"}>
        We have reset your password, please continue to signin. <br />
        {email}
      </Typography>
      <Button href="/signin">Signin</Button>
    </Stack>
  );
}

function NewPassword({ hasSubmit }) {
  const { setisLoading } = React.useContext(Context);
  const [error, seterror] = useState("");
  let { resettoken } = useParams();

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (payload) => {
      setisLoading(true);
      seterror("");

      axios
        .post(process.env.REACT_APP_API_BASE_URL + "auth/passwordreset", {
          ...payload,
          reset_token: resettoken,
        })
        .then((res) => {
          setisLoading(false);
          if (res.status === 200) {
            //logic
            hasSubmit(true);
          }
        })
        .catch((error) => {
          setisLoading(false);
          console.log(error);
          seterror("incorrect email or password");
        });
    },
  });

  return (
    <Stack alignItems={"center"} spacing={2}>
      <GlassBg>
        <Typography component="h1" variant="h5" align="center">
          Enter New Password
        </Typography>
        <TextField
          fullWidth
          autoComplete="new-password"
          id="password"
          name="password"
          label="New password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Password reset
        </Button>
      </GlassBg>

      <Typography
        variant="caption"
        display="block"
        color="red"
        align="center"
        minHeight={"20px"}
        mt={1}
      >
        {error}
      </Typography>
    </Stack>
  );
}
