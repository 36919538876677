import moment from "moment";

const ab = {
  money_format: function (data) {
    return data.toLocaleString("de-DE");
  },
  date_format: function (data) {
    return moment(data).format("DD MMM YYYY");
  },
};

// ==================================================================================================================

const format = "DD MMM YYYY";
const format2 = "DD/MM/YYYY";
const format_time = "DD MMM YYYY h.mma";

function excelDateParse(data) {
  return new Date(Math.round((data - 25569) * 86400 * 1000));
}

const fdate = {
  today: ab.date_format(new Date()),
  today2: new Date(),
  todayYear: new Date().getFullYear(),

  format: (data) => moment(data).format(format),
  format2: (data) => moment(data).format(format2),
  format3: (data) => moment(data).format("MMM DD, YYYY"),
  format4: (data) => moment(data).format("h.mma"),
  format5: (data) => moment(data).format("MMM YYYY"),
  format6: (data) => moment(excelDateParse(data)).format("MMM YYYY"),

  format_time: (data) => moment(data).format(format_time),
  add: (data) => moment().add(data, "days").format(format),
  isToday: (data) => {
    return new Date(data).toDateString() === new Date().toDateString()
      ? true
      : false;
  },
};

// ==================================================================================================================
const fcurr = {
  format: (data) => "IDR " + formatter.format(data),
  format2: (data) => formatter2.format(data),
};

//with dot .
var formatter = new Intl.NumberFormat("de-DE", {
  undefined,
});

//with comma ,
var formatter2 = new Intl.NumberFormat("en-US", {
  undefined,
});

// ==================================================================================================================

// const time_format = "h:mma";

const ftime = {
  format: (data) => moment(data, "HH:mm:ss").format("h:mm a"),
};

// ==================================================================================================================

export { fdate, fcurr, ftime };
