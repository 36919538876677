import React from "react";
import { Box, Stack } from "@mui/material";
import Context from "provider/context";

export default function App(props) {
  const { user } = React.useContext(Context);
  return (
          <Stack width={"100%"} height="100vh" minHeight="1200px" overflow={"hidden"}>
      <iframe
        src={"https://eform.bpot.co.id/?u="+user.email+"&m="+user.phone}
        title="description"
        allow="camera *;microphone *"
        frameBorder="0"
        width={"100%"}
        height="100%"
          minHeight="1200px"
      ></iframe>
    </Stack>
  );
}
