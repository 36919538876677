import React from "react";

import Context from "provider/context";
import useAxios from "axios-hooks";
import { Stack, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import TimeStamp from "component/app/timeStamp";
import { Link } from "react-router-dom";
import CheckImage from "component/app/imgCheck";
import noImg from "assets/img/noimg.svg";
import { useLocation } from "react-router-dom";

import { parse } from "rss-to-json";
const tags = ["Stock", "IDX", "IHSG"];

export default function App(props) {
  const { state } = useLocation();
  const [data, setdata] = React.useState();
  console.log(state);

  const { setisLoading } = React.useContext(Context);

  React.useEffect(() => {
    async function fetchData() {
      setisLoading(true);
      parse(state.link)
        .then((rss) => {
          // console.log(rss);
          let temp = data;
          rss.items.map((d, ix) => {
            let payload = {
              id: ix,
              title: d.title,
              content: d.description,
              created_at: d.published,
              topic: d.title,
              link: d.link,
              img: d.media.thumbnail,
            };
            temp.push(payload);
          });
          setdata(temp);
        })
        .then((e) => {
          setisLoading(false);
        });
    }
    fetchData();
  }, [data]);

  if (!data) return "";

  return (
    <Stack width={"100%"} my={3} spacing={3}>
      {/* <Main data={data.find((d) => d.id == id)} />
      <Other data={data.filter((d) => d.id != id)} /> */}
    </Stack>
  );
}

function Main({ data }) {
  return (
    <>
      <Stack
        sx={{
          background:
            "linear-gradient(90.46deg, #46389B 2.01%, rgba(51, 40, 108, 0) 99.56%)",
        }}
      >
        <Typography variant="p">INVESTMENT NEWS</Typography>
      </Stack>
      <Typography variant="h4">{data.title}</Typography>
      <TimeStamp data={data.created_at} size="p" />

      <Stack height={"400px"}>
        <img
          src={
            process.env.REACT_APP_ASSET_BASE_URL + "uploads/" + data.imgcover
          }
          className="img-contain"
          alt=""
          onError={(e) => {
            e.target.onError = null;
            e.target.src = noImg;
          }}
        />
      </Stack>

      <Stack
        height={"100%"}
        pr={2}
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
      <Stack spacing={1}>
        <Typography variant="p">Tags:</Typography>
        <Stack direction={"row"} spacing={2}>
          {data.tags.split(",").map((d, ix) => (
            <Stack
              key={ix}
              width={"90px"}
              height={"24px"}
              borderRadius={"4px"}
              className="bg-glass center"
            >
              <Link to="/news">
                <Typography variant="caption">{d}</Typography>
              </Link>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </>
  );
}

function Other({ data }) {
  return (
    <Stack>
      <Typography variant="p" my={3}>
        You may also like
      </Typography>
      <Grid container spacing={3}>
        {data
          .filter((d) => d.newsgroup.title === "News")
          .slice(0, 2)
          .map((d) => (
            <Grid item xs={12} md={4} key={d.id}>
              <Link to={`/news/view/${d.id}`} key={d.id}>
                <Stack
                  className="bg-glass"
                  p={2}
                  borderRadius={"12px"}
                  spacing={2}
                  onClick={window.scrollTo(0, 0)}
                >
                  <Typography variant="caption" className="f-uppercase">
                    {d.topic}
                  </Typography>
                  <Typography variant="p">{d.title}</Typography>
                </Stack>
              </Link>
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
}
