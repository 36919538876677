const sites = [
  { name: "Home", path: "/", private: false },
  { name: "Eform", path: "/eform" },
  {
    name: "News & Research",
    path: "#",
    private: true,
    sub: [
      { name: "Features", path: "/features", private: false },
      { name: "Research", path: "/news" },
      { name: "News", path: "/news" },
    ],
  },
  {
    name: "Education",
    path: "#",
    private: false,
    sub: [
      { name: "How To Invest", path: "/education" },
      { name: "Investing Glossarium", path: "/glossarium" },
      {
        name: "Calculator",
        path: "/calculator",
        sub: [
          { name: "Trading Calculator", path: "/kalkulator/tradingCalculator" },
          { name: "Right Issue", path: "/kalkulator/rightissue" },
          {
            name: "Stock Split & reserve",
            path: "/kalkulator/stocksplitreserve",
          },
        ],
      },
    ],
  },
  { name: "Chart", path: "/screener", private: true },
  { name: "Invest Hub", path: "/stream", private: true },
];

const sitesFeatures = [
  { name: "News", path: "#" },
  { name: "Research", path: "#" },
  { name: "Calendar", path: "#" },
  { name: "Calculator", path: "#" },
  { name: "AI Trade", path: "#" },
  { name: "Affiliate Programme", path: "#" },
  { name: "Terms & Conditions", path: "#" },
];

const sitesEducation = [
  { name: "How To", path: "#" },
  { name: "Investing Glossarium", path: "#" },
  { name: "Risk & Reward", path: "#" },
];

export { sites, sitesFeatures, sitesEducation };
