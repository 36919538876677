import React from "react";
import { useParams, Navigate } from "react-router-dom";

import useAxios from "axios-hooks";
import { Stack, Button } from "@mui/material";

export default function App() {
  let { id } = useParams();

  const [{ error }] = useAxios(
    {
      url: process.env.REACT_APP_API_BASE_URL + "auth/emailverification",
      method: "post",
      data: {
        id: id,
      },
    },
    {
      useCache: false,
    }
  );

  if (error) {
    return <Navigate to="/error" />;
  }
  return (
    <Stack
      minHeight="calc(100vh - 96px)"
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      direction={"column"}
    >
      Your account has been activated.
      <Button href="/signin">Continue to signin</Button>
    </Stack>
  );
}
