import React from "react";
import "assets/css/font.css";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import Context from "./context";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider } from "@mui/material/styles";

const ButtonPallet = {
  primary: {
    // main: "#2E4C83",
    main: "#20C293",
  },
  secondary: {
    main: "#4CE2E4",
  },
  report: {
    g: "#3aff67",
    r: "#be3735",
  },
  white: {
    main: "#FFFFFF",
    light: "#BB1C2A",
    dark: "#BB1C2A",
  },
  pink: {
    main: "#363C45",
  },
  accent: {
    bgdark: "#363C45",
  },
  error: {
    light: "#BB1C2A",
    main: "#BB1C2A",
    dark: "#BB1C2A",
  },
  shadow: {
    // main: "rgba(0, 0, 0, 0.7) 100%",
    main: "red",
    gradient:
      "linear-gradient(0deg,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.7) 100%)",
  },
};

const darkPallet = {
  mode: "dark",
  background: {
    default: "#1D2027",
    paper: "#272727",
  },
  ...ButtonPallet,
};

const lightPallet = {
  mode: "light",
  background: {
    default: "#ffffff",
  },
  ...ButtonPallet,
};

export default function App(props) {
  const { theme } = React.useContext(Context);

  let themeLoader = createTheme({
    palette: theme === "dark" ? darkPallet : darkPallet,
    typography: {
      fontFamily: "Poppins",
      h1: {
        fontWeight: 600,
      },
      h2: {
        fontWeight: 600,
      },
      h3: {
        fontWeight: 600,
      },
      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: "normal",
      },
      h6: {},
      body2: {
        fontWeight: "normal",
      },
      caption: {
        fontWeight: "normal",
      },
      overline: {
        lineHeight: "unset",
      },
    },
    components: {
      MuiAppBar: {
        defaultProps: {
          enableColorOnDark: true,
        },
      },
      MuiTextField: {
        defaultProps: {
          // size: "small",
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
      },
      MuiButton: {
        styleOverrides: {
          containedSecondary: {
            fontWeight: "bold",
          },
        },
      },

      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: "h2",
            h2: "h2",
            h3: "h2",
            h4: "h2",
            h5: "h2",
            h6: "h2",
            subtitle1: "p",
            subtitle2: "p",
            caption: "p",
            body1: "p",
            body2: "p",
            overline: "p",
          },
        },
      },
    },
  });

  // console.log(themeLoader);

  return (
    <ThemeProvider theme={responsiveFontSizes(themeLoader)}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>{props.children}</StyledEngineProvider>
    </ThemeProvider>
  );
}
