import React from "react";

import { TextField, Button, Stack, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Context from "provider/context";
import { Link } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const validationSchema = yup.object({
  title: yup.string("Enter your fullname").required("Fullname is required"),
  content: yup.string("Enter your fullname").required("Fullname is required"),
});

export default function App(props) {
  const { setisLoading, user } = React.useContext(Context);
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
    },
    validationSchema: validationSchema,
    onSubmit: (payload) => {
      setisLoading(true);
      axios
        .post(process.env.REACT_APP_API_BASE_URL + "stream/add", {
          ...payload,
          user_id: user.id,
        })
        .then((res) => {
          setisLoading(false);
          if (res.status === 200) {
            navigate("/stream", { replace: true });
          }
        })
        .catch((error) => {
          setisLoading(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <Stack
        direction={"column"}
        width={"100%"}
        alignItems={"center"}
        spacing={2}
        pt={2}
        py={{ xs: 3, md: 5 }}
      >
        <Stack
          direction={"row"}
          spacing={3}
          width={"100%"}
          alignItems={"center"}
        >
          <Link to="/stream" className="center">
            <ArrowBackIcon fontSize="large" />
          </Link>
          <Typography variant="h4">New Stream</Typography>
        </Stack>
        <TextField
          name="title"
          fullWidth
          id="fullname"
          label="Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <TextField
          fullWidth
          name="content"
          label="Content"
          multiline
          rows={8}
          value={formik.values.content}
          onChange={formik.handleChange}
          error={formik.touched.content && Boolean(formik.errors.content)}
          helperText={formik.touched.content && formik.errors.content}
        />

        <Stack direction={"row"} width={"100%"} spacing={3}>
          <Link to="/stream" style={{ width: "100%" }}>
            <Button fullWidth variant="outlined" color="white">
              Cancel
            </Button>
          </Link>
          <Button type="submit" fullWidth variant="contained">
            Add new Stream
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
