import React, { useState, useLayoutEffect, useRef } from "react";

import { Screener } from "react-ts-tradingview-widgets";
import OpeningAccountIframe from "component/app/oaoIframe";
import { useNavigate, Navigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
//www.tradingview.com/widget/
//https://jorrinkievit.github.io/react-ts-tradingview-widgets/docs/components/Screener

import Typography from "@mui/material/Typography";

export default function App(props) {
  const token = localStorage.getItem("AuthToken");
  const targetRef = useRef();
  const [graphHeight, setgraphHeight] = useState(512);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setgraphHeight(targetRef.current.offsetHeight);
    }
  }, []);

  if (!token) return <Navigate to="/signin" />;
  return (
    <Stack
      width={"100vw"}
      display={"flex"}
      flexGrow={1}
      ref={targetRef}
      spacing={2}
      height="auto"
      // minHeight={"100vh"}
      py={2}
      // position="absolute"
      // left={0}
      // bgcolor="red"
    >
      <OpeningAccountIframe />
    </Stack>
  );
}
